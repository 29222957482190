<template>
  <div>
    <h2 v-t="'employeeSettings'" class="text-2xl font-weight-semibold mb-4" />
    <v-card id="account-setting-card">
      <v-tabs v-model="currentTab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.icon">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ $t(tab.title) }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab" touchless>
        <v-tab-item v-if="$can(ACTIONS.READ, PRIVATE.SETTINGS.EMPLOYEES)">
          <employee-settings-tab-statuses />
        </v-tab-item>
        <v-tab-item v-if="$can(ACTIONS.READ, PRIVATE.SETTINGS.EMPLOYEES)">
          <employee-settings-tab-custom-fields />
        </v-tab-item>
        <v-tab-item v-if="$can(ACTIONS.READ, PRIVATE.SETTINGS.EMPLOYEES)">
          <employee-settings-tab-tags />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiText, mdiTagOutline, mdiBookEditOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import EmployeeSettingsTabStatuses from './employee-settings-tab-statuses/EmployeeSettingsTabStatuses.vue'
import EmployeeSettingsTabCustomFields from './employee-settings-tab-custom-fields/EmployeeSettingsTabCustomFields.vue'
import EmployeeSettingsTabTags from './employee-settings-tab-tags/EmployeeSettingsTabTags.vue'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

export default {
  components: {
    EmployeeSettingsTabStatuses,
    EmployeeSettingsTabCustomFields,
    EmployeeSettingsTabTags,
  },
  setup() {
    const currentTab = ref(null)
    const tabs = [
      { icon: mdiText, title: 'statuses' },
      { icon: mdiBookEditOutline, title: 'customFields' },
      { icon: mdiTagOutline, title: 'tags' },
    ]

    return {
      tabs,
      currentTab,

      ACTIONS,
      PRIVATE,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
